// 景观环境 监测数据
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'MC',
                    label: '景观风貌名称',
                    minWidth: '',
                },
                {
                    prop: 'XZSFFSBH_DESC',
                    label: '现状是否发生变化',
                    minWidth: '',
                },
                {
                    prop: 'RKSJ',
                    label: '时间',
                    minWidth: '',
                },
                {
                    prop: 'YQBHYY_DESC',
                    label: '引起变化的原因',
                    minWidth: '',
                },
                {
                    prop: 'YXPG_DESC',
                    label: '影响评估',
                    minWidth: '',
                }, {
                    label: "现场照片",
                    prop: "LJ",
                    minWidth: "",
                    showImg: true,
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;